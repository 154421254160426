import {
  Layout,
  Typography,
  Tabs,
  Modal,
  List,
  Divider,
  Avatar,
  Tooltip,
} from "antd";
import { useMemo, useState } from "react";
import { FaChevronDown, FaInfoCircle, FaQrcode } from "react-icons/fa";
import { InfoCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { BJFlex } from "../../components/styled";
import BJSelect from "../../components/theme/atoms/BJSelect";
import BJCopy from "../../components/theme/atoms/BJCopy";
import { IconWrapper } from "../../components/styled/BJCommonStyles";
import BJInput from "../../components/theme/atoms/BJInput";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import styles from "../../styles/pages/deeplinkmain.module.scss";
import { InputWrapper, QRGenerator } from "./style";
import {
  useAppTakeOvers,
  useArticles,
  useBirthStories,
  useBlogPosts,
  useChecklists,
  useChildBlogPosts,
  useCompetitions,
  useContentCollections,
  useDietaryAdvices,
  useFAQ,
  useGeneralBlogPosts,
  useMiniJourneys,
  useOffers,
  usePodcasts,
} from "../../context";
import { DeepLinkType } from "../../utils";
import { HOWTOQR, noParamsSreens } from "./constants";
import {
  createDeepLink,
  contentDeepLink,
  getDeepLinkPrefix,
  createDynamicLink,
  setSearchKeyword,
} from "./methods";
import { useCountry } from "../../context";

const { TabPane } = Tabs;

const screens: { [key: string]: string } = {
  BirthPlanIntro: "birthplan",
  DietaryAdvices: "DietaryAdvices",
  BirthStoryList: "BirthStoryList",
  AccountCreate: "SignUp",
  AccountLogin: "AccountLogin",
  Home: "Home",
  Content: "Content",
  CompetitionList: "competitions",
  CompetitionDetail: "competitions/:id",
  WeekInformationTabbed: "weekInformationTabbedFromHome",
  MonthlyInformationTabbed: "monthlyInformationTabbedFromHome",
  BlogList: "BlogList",
  ChildBlogList: "ChildBlogList",
  GeneralBlogPostList: "GeneralBlogPostList",
  PodcastList: "PodcastList",
  Checklist: "checklists",
  AllOffers: "offers",
  EditProfile: "settings/EditProfile",
  Pregnancy: "settings/Pregnancy",
  SettingsScreen: "settings/SettingsScreen",
  Support: "settings/Support",
  About: "settings/About",
  Share: "settings/Share",
  Notifications: "settings/Notifications",
  FAQ: "Faqs",
  WeekInformationContent: "WeekInformationTabbed",
  MonthlyInformationContent: "MonthlyInformation",
  BirthPlanSummaryLanding: "BirthPlanSummaryLanding",
  BirthStoryLanding: "BirthStoryLanding",
  MoodTracker: "MoodTracker",
  Babylog: "Babylog",
  Tools: "Tools",
  MyRewards: "MyRewards",
  WebView: "webview",
  ProfileTab: "profile",
  PointsAndRewards: "pointsAndRewards",
};

const weeklyTabs: { [key: string]: number } = {
  Baby: 0,
  Mother: 1,
  Partner: 2,
};

const monthlyTabs: { [key: string]: number } = {
  Development: 0,
  Parent: 1,
  Tips: 2,
};

interface Props {
  fromHOC?: { isVisible: boolean; title: string };
  copyToHOC?: (text: string) => void;
  copyContentIdToHOC?: (contentId: string) => void;
}

export const DeepLink = (props: Props) => {
  const { copyToHOC, copyContentIdToHOC, fromHOC } = props;
  const [selection, setSelection] = useState("Article");
  const [params, setParams] = useState<{ [key: string]: string } | null>(null);
  const [inputData, setInputData] = useState<{ key: string; value: string }>({
    key: "",
    value: "",
  });
  const [offerType, setOfferType] = useState(null);
  const { articles, categories, loading: articlesLoading } = useArticles();
  const { questions, loading: faqLoading } = useFAQ();
  const { podcasts, getEpisodesById, loading: podcastLoading } = usePodcasts();
  const { checklists, loading: checklistLoading } = useChecklists();
  const { blogPosts: pregnancyBlogPosts, loading: blogPostsLoading } =
    useBlogPosts();
  const { blogPosts: childBlogPosts, loading: childBlogPostLoading } =
    useChildBlogPosts();
  const { birthStories: birthBlogPosts, loading: birthStoryLoading } =
    useBirthStories();
  const { generalBlogPosts, loading: generalBlogPostsLoading } =
    useGeneralBlogPosts();
  const { offers, loading: offersLoading } = useOffers();
  const { dietaryAdvices, loading: dietaryAdvicesLoading } =
    useDietaryAdvices();
  const {
    activeContentCollections: contentCollection,
    loading: contentCollectionLoading,
  } = useContentCollections();
  const { activeAppTakeOvers: appTakeOvers, loading: appTakeOverLoading } =
    useAppTakeOvers();
  const { miniJourneys, loading: miniJourneyLoading } = useMiniJourneys();
  const { competitions, loading: competitionsLoading } = useCompetitions();
  const { currentCountry, primaryLocale } = useCountry();

  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [selectedContentId, setSelectedContentId] = useState("");
  const [podcastId, setPodcastId] = useState("");

  const deepLinkPrefix = getDeepLinkPrefix();

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const QRModal = (value: string) => {
    Modal.info({
      content: <QRGenerator value={value} />,
      icon: <InfoCircleOutlined onClick={() => openInNewTab(HOWTOQR)} />,
      okButtonProps: { hidden: true },
      okCancel: true,
      cancelText: "Close",
      maskClosable: true,
      style: { display: "flex", justifyContent: "center" },
    });
  };

  const podcastEpisodeModal = (id: string) => {
    setPodcastId(id);
    const episodes = getEpisodesById(id);
    Modal.info({
      content: (
        <List
          itemLayout="vertical"
          dataSource={
            episodes?.map(episode => ({
              id: episode.id,
              title: episode.translations[primaryLocale?.key]?.name,
              imageUrl: episode.translations[primaryLocale?.key]?.url,
            })) || []
          }
          className={styles.podcastEpisodeModal}
          renderItem={item => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedContentId(item.id);
                Modal.destroyAll();
              }}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.imageUrl} />}
                title={item.title}
              />
            </List.Item>
          )}
        />
      ),
      okButtonProps: { hidden: true },
      okCancel: true,
      cancelText: "Close",
      maskClosable: true,
      style: { display: "flex", justifyContent: "center" },
    });
  };

  const setParamsDeepLink = () => {
    setParams(prev => ({ ...prev, [inputData.key]: inputData.value }));
    setInputData({
      key: "",
      value: "",
    });
  };

  const removeParamDeepLink = (key: string) => {
    setParams(prev => {
      const tempData = { ...prev };
      delete tempData[key];
      return tempData;
    });
  };

  const clearParamDeepLink = () => {
    setParams(null);
    setInputData({
      key: "",
      value: "",
    });
  };

  const generatedScreenDeepLink = useMemo(() => {
    const urlSearchParams = new URLSearchParams({
      ...(params || {}),
      cc: currentCountry?.abb || "",
    });
    return `${deepLinkPrefix.domain}/screen/${
      screens[selection]
    }?${urlSearchParams.toString()}`;
  }, [currentCountry?.abb, deepLinkPrefix.domain, params, selection]);

  const generatedContentDeepLink = useMemo(() => {
    return createDeepLink({
      type: DeepLinkType[selection as keyof typeof DeepLinkType],
      id: selectedContentId,
      params: { podcastId },
      countryCode: currentCountry?.abb,
    });
  }, [currentCountry?.abb, podcastId, selectedContentId, selection]);

  const contentData = useMemo(
    () =>
      contentDeepLink({
        selection,
        searchKeyWord,
        articles,
        questions,
        podcasts,
        checklists,
        pregnancyBlogPosts,
        childBlogPosts,
        birthBlogPosts,
        generalBlogPosts,
        offers,
        dietaryAdvices,
        categories,
        contentCollection,
        appTakeOvers,
        miniJourneys,
        competitions,
        locale: primaryLocale,
      }),
    [
      selection,
      searchKeyWord,
      articles,
      questions,
      podcasts,
      checklists,
      pregnancyBlogPosts,
      childBlogPosts,
      birthBlogPosts,
      generalBlogPosts,
      offers,
      dietaryAdvices,
      categories,
      contentCollection,
      appTakeOvers,
      miniJourneys,
      competitions,
      primaryLocale,
    ]
  );

  const renderDeepLink = (
    deepLink: string,
    showDynamicLink = true,
    showSecondLink = false
  ) => {
    const renderLinkSection = (
      deepLinkText: string,
      deepLinkLabel: string,
      isSecondLink = false
    ) => {
      const link = isSecondLink
        ? `${deepLinkText}&secondStep=true`
        : deepLinkText;
      const validateSelection =
        ![...Object.keys(screens), "Extra"].find(
          value => selection === value
        ) && !selectedContentId;
      const dynamicLink = createDynamicLink(encodeURIComponent(link));

      return (
        <BJFlex flex={1}>
          <BJFlex mTop={1} key={deepLinkPrefix.domain}>
            <BJFlex row>
              <Typography.Title level={4}>{deepLinkLabel}</Typography.Title>
              <BJFlex row mLeft={0.5} mTop={0.4}>
                <Tooltip title="Deep links: Links that can open the app/screen in app">
                  <FaInfoCircle />
                </Tooltip>
              </BJFlex>
              <BJCopy disabled={validateSelection} textToCopy={link} />

              <IconWrapper
                disabled={validateSelection}
                hoverEffect
                onClick={() => QRModal(link)}
              >
                <FaQrcode />
              </IconWrapper>

              {fromHOC && fromHOC.isVisible && copyToHOC && (
                <BJButton
                  disabled={validateSelection}
                  buttonType={ButtonTypes.Save}
                  style={{ width: "10rem", marginLeft: "1rem" }}
                  onClick={() => {
                    copyContentIdToHOC && copyContentIdToHOC(selectedContentId);
                    copyToHOC(link);
                  }}
                >
                  {`Copy to ${fromHOC.title}`}
                </BJButton>
              )}
            </BJFlex>
            {!validateSelection && <Typography.Link>{link}</Typography.Link>}
            {validateSelection && (
              <Typography.Text type="warning">
                Please select a content from the right side panel to generate
                the link
              </Typography.Text>
            )}
          </BJFlex>

          {showDynamicLink && (
            <BJFlex mTop={4} key={deepLinkPrefix.domain}>
              <BJFlex row>
                <Typography.Title level={4}>
                  Dynamic Link {isSecondLink && "Second Step"}
                </Typography.Title>
                <BJFlex row mLeft={0.5} mTop={0.4}>
                  <Tooltip title="Fallback for deep links, will redirect to download app if not installed. Also used for instagram stories.">
                    <FaInfoCircle />
                  </Tooltip>
                </BJFlex>
                <BJCopy disabled={validateSelection} textToCopy={dynamicLink} />

                <IconWrapper
                  disabled={validateSelection}
                  hoverEffect
                  onClick={() => QRModal(dynamicLink)}
                >
                  <FaQrcode />
                </IconWrapper>

                {fromHOC && fromHOC.isVisible && copyToHOC && (
                  <BJButton
                    disabled={validateSelection}
                    buttonType={ButtonTypes.Save}
                    style={{ width: "10rem", marginLeft: "1rem" }}
                    onClick={() => {
                      copyContentIdToHOC &&
                        copyContentIdToHOC(selectedContentId);
                      copyToHOC(dynamicLink);
                    }}
                  >
                    {`Copy to ${fromHOC.title}`}
                  </BJButton>
                )}
              </BJFlex>

              {!validateSelection && (
                <Typography.Link className={styles.dynamicLink}>
                  {dynamicLink}
                </Typography.Link>
              )}
              {validateSelection && (
                <Typography.Text type="warning">
                  Please select a content from the right side panel to generate
                  the link
                </Typography.Text>
              )}
            </BJFlex>
          )}
        </BJFlex>
      );
    };

    return (
      <BJFlex>
        {renderLinkSection(deepLink, "Deep Link")}
        {showSecondLink &&
          renderLinkSection(deepLink, "Deep Link Second Step", true)}
      </BJFlex>
    );
  };

  const linkComponent = (path: string, title: string, custom?: boolean) => {
    return (
      <>
        <Typography.Title level={3}>{title}</Typography.Title>
        {renderDeepLink(
          custom ? path : `${deepLinkPrefix.domain}${path}`,
          !custom
        )}
        <Divider />
      </>
    );
  };

  const renderParamsForWeeklyAndMonthly = () => {
    if (
      selection !== "WeekInformationTabbed" &&
      selection !== "MonthlyInformationTabbed"
    ) {
      return <></>;
    }
    const isWeekly = selection === "WeekInformationTabbed";
    const tabs = isWeekly ? weeklyTabs : monthlyTabs;
    return (
      <>
        <BJFlex row alignCenter>
          <InputWrapper>
            <Typography.Paragraph>Tab</Typography.Paragraph>
            <BJSelect
              onChange={value => {
                setInputData(prev => ({
                  ...prev,
                  key: "tab",
                  value: String(tabs[value]),
                }));
              }}
              options={Object.keys(tabs).map((item: string) => ({
                value: item,
                key: item,
                label: item,
              }))}
              suffixIcon={<FaChevronDown />}
            />
          </InputWrapper>
        </BJFlex>
        <InputWrapper style={{ marginTop: "1.2rem" }}>
          <BJButton onClick={setParamsDeepLink}>Add</BJButton>
        </InputWrapper>
        <BJFlex row alignCenter>
          <InputWrapper>
            <Typography.Paragraph>
              {isWeekly ? "Week" : "Month"}
            </Typography.Paragraph>
            <BJSelect
              onChange={value => {
                setInputData(prev => ({
                  ...prev,
                  key: isWeekly ? "week" : "month",
                  value: String(value),
                }));
              }}
              options={Array(isWeekly ? 42 : 24)
                .fill(0)
                .map((_, index) => ({
                  value: index + 1,
                  key: index + 1,
                  label: index + 1,
                }))}
              suffixIcon={<FaChevronDown />}
            />
          </InputWrapper>
        </BJFlex>
        <InputWrapper style={{ marginTop: "1.2rem" }}>
          <BJButton onClick={setParamsDeepLink}>Add</BJButton>
        </InputWrapper>
      </>
    );
  };

  const renderAddParams = () => {
    if (
      selection === "WeekInformationTabbed" ||
      selection === "MonthlyInformationTabbed"
    ) {
      return <></>;
    }
    return (
      <BJFlex row alignCenter>
        <InputWrapper>
          <Typography.Paragraph>Key</Typography.Paragraph>
          <BJInput
            allowClear
            onChange={e => {
              setInputData(prev => ({ ...prev, key: e.target.value }));
            }}
            value={inputData.key}
          />
        </InputWrapper>
        <InputWrapper>
          <Typography.Paragraph>Value</Typography.Paragraph>
          <BJInput
            allowClear
            onChange={e => {
              setInputData(prev => ({
                ...prev,
                value: e.target.value,
              }));
            }}
            value={inputData.value}
          />
        </InputWrapper>
        <InputWrapper style={{ marginTop: "1.2rem" }}>
          <BJButton onClick={setParamsDeepLink}>Add</BJButton>
        </InputWrapper>
      </BJFlex>
    );
  };

  const ContentTab = () => {
    const isLoading =
      articlesLoading ||
      faqLoading ||
      podcastLoading ||
      offersLoading ||
      blogPostsLoading ||
      birthStoryLoading ||
      childBlogPostLoading ||
      generalBlogPostsLoading ||
      dietaryAdvicesLoading ||
      checklistLoading ||
      contentCollectionLoading ||
      appTakeOverLoading ||
      miniJourneyLoading ||
      competitionsLoading;
    return (
      <TabPane tab="Content" key="1">
        <BJSelect
          onChange={setSelection}
          options={Object.keys(DeepLinkType).map((item: string) => ({
            value: item,
            key: item,
            label: item,
          }))}
          defaultValue="Article"
          suffixIcon={<FaChevronDown />}
          value={selection || "Article"}
        />
        <BJFlex row>
          {renderDeepLink(generatedContentDeepLink, true, offerType === "5")}

          <BJFlex>
            <Divider type="vertical" />
          </BJFlex>
          <BJFlex flex={1}>
            {!isLoading && (
              <BJInput
                placeholder="Please enter three or more characters to search"
                onChange={e => setSearchKeyword(e, setSearchKeyWord)}
              />
            )}
            <List
              itemLayout="vertical"
              dataSource={contentData}
              className={styles.contentList}
              loading={isLoading}
              renderItem={item => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    DeepLinkType[selection as keyof typeof DeepLinkType] ===
                    DeepLinkType.PodcastEpisode
                      ? podcastEpisodeModal(item.id)
                      : setSelectedContentId(item.id);

                    DeepLinkType[selection as keyof typeof DeepLinkType] ===
                    DeepLinkType.Offer
                      ? setOfferType(item.type)
                      : setOfferType(null);
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={item.imageUrl} />}
                    title={item.title}
                  />
                </List.Item>
              )}
            />
          </BJFlex>
        </BJFlex>
      </TabPane>
    );
  };

  const ScreenTab = () => (
    <TabPane tab="Screen" key="2">
      <BJSelect
        onChange={v => {
          setSelection(v);
          clearParamDeepLink();
        }}
        options={Object.keys(screens).map((item: string) => ({
          value: item,
          key: item,
          label: item,
        }))}
        defaultValue="Home"
        value={selection || "Home"}
        suffixIcon={<FaChevronDown />}
      />
      <BJFlex mTop={1} mLeft={1} row>
        <BJFlex>
          {renderDeepLink(generatedScreenDeepLink)}
          {!noParamsSreens.includes(selection) && (
            <>
              <Typography.Title style={{ marginTop: "2rem" }} level={4}>
                Add Params
              </Typography.Title>
              {renderAddParams()}
            </>
          )}
          {renderParamsForWeeklyAndMonthly()}
          <List
            itemLayout="vertical"
            dataSource={Object.keys(params ?? {})}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <IconWrapper
                      hoverEffect
                      onClick={() => removeParamDeepLink(item)}
                    >
                      <CloseCircleOutlined />
                    </IconWrapper>
                  }
                  title={item}
                  description={params[item]}
                />
              </List.Item>
            )}
          />
        </BJFlex>
      </BJFlex>
    </TabPane>
  );

  const ExtraTab = () => (
    <TabPane tab="Extra" key="3">
      <BJFlex mTop={1} mLeft={1}>
        {/* This temporarily done to isolate this feature in Region SE (Sweden) */}
        {currentCountry?.abb === "se" &&
          linkComponent(
            `/screen/AIChatLanding?cc=${currentCountry?.abb}`,
            "Open Daily Doula"
          )}
        {linkComponent(
          `/screen/Home?tips=true&cc=${currentCountry?.abb}`,
          "Open Daily Doula From tips"
        )}
        {linkComponent(
          `/screen/checklists?selectTab=0&cc=${currentCountry?.abb}`,
          "Open checklist tab - Pregnancy"
        )}
        {linkComponent(
          `/screen/checklists?selectTab=1&cc=${currentCountry?.abb}`,
          "Open checklist tab - Child 0-1"
        )}
        {linkComponent(
          `/screen/checklists?selectTab=2&cc=${currentCountry?.abb}`,
          "Open checklist tab - Child 1-2"
        )}
        {linkComponent(
          `/screen/Home?selectTab=0&cc=${currentCountry?.abb}`,
          "Open active pregnancy"
        )}
        {linkComponent(`/screen/Home?scrollToPoll=true`, "Open Polls")}
        {linkComponent(
          `/screen/Home?selectTab=1&cc=${currentCountry?.abb}`,
          "Open active child"
        )}
        {linkComponent(
          "itms-apps://apps.apple.com/app/id1515225502?action=write-review",
          "Open AppStore review",
          true
        )}
        {linkComponent(
          "market://details?id=se.babyjourney",
          "Open Play Store review",
          true
        )}
      </BJFlex>
    </TabPane>
  );

  const setDefaultKeyByTab = (key: string) => {
    switch (key) {
      case "1": {
        setSelection("Article");
        setSelectedContentId("");
        break;
      }
      case "2": {
        setSelection("Home");
        break;
      }
      case "3": {
        setSelection("Extra");
        break;
      }
      default: {
        setSelection("Article");
        break;
      }
    }
  };

  return (
    <Layout>
      <BJFlex>
        <BJFlex row justifySpaceBetween pRight={2}>
          <Typography.Title level={2}>Link Generator</Typography.Title>
        </BJFlex>

        <Tabs defaultActiveKey="1" onChange={setDefaultKeyByTab}>
          {ContentTab()}
          {ScreenTab()}
          {ExtraTab()}
        </Tabs>
      </BJFlex>
    </Layout>
  );
};
